import GridWrapper from "@components/elements/GridWrapper";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  alpha,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useRouter } from "next/router";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.light,
  },
  aboutCard: {
    background: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  primaryBG: {
    margin: theme.spacing(1),
    background: alpha(theme.palette.primary.main, 0.2),
    // background: theme.palette.primary.main,
    // color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  secondaryBG: {
    margin: theme.spacing(1),
    background: alpha(theme.palette.primary.main, 0.2),
    // background: theme.palette.secondary.main,
    // color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
  tertiaryBG: {
    margin: theme.spacing(1),
    background: alpha(theme.palette.primary.main, 0.2),
    // background: theme.palette.tertiary.main,
    // color: theme.palette.getContrastText(theme.palette.tertiary.main),
  },
}));

export default function MainAbout(): JSX.Element {
  const router = useRouter();
  const classes = useStyles();

  const navigateToCoaches = () => {
    router.push("coaches");
  };
  const navigateToWorkouts = () => {
    router.push("workouts");
  };
  const naviagteToMemberships = () => {
    router.push("membership");
  };

  return (
    <Box className={classes.root}>
      <GridWrapper itemSizes={{ xs: 12, sm: 12, md: 4 }} spacing={2}>
        <Card elevation={0} className={classes.tertiaryBG}>
          <CardContent>
            <Typography variant="h5" align="center">
              Industry-Leading <br />
              Coaches
            </Typography>
            <Box textAlign="center" p={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={navigateToCoaches}
              >
                Meet Our Team
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Card elevation={0} className={classes.primaryBG}>
          <CardContent>
            <Typography variant="h5" align="center">
              Multiple Workout <br />
              Categories
            </Typography>
            <Box textAlign="center" p={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={navigateToWorkouts}
              >
                Try A Workout
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Card elevation={0} className={classes.secondaryBG}>
          <CardContent>
            <Typography variant="h5" align="center">
              Subscription Plans: <br /> Join Now!
            </Typography>
            <Box textAlign="center" p={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={naviagteToMemberships}
              >
                See Our Plans
              </Button>
            </Box>
          </CardContent>
        </Card>
      </GridWrapper>
      <Divider />
    </Box>
  );
}
