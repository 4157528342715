import builder, { BuilderContent } from "@builder.io/react";
import MainAbout from "@components/about";
import CoachRowWithData from "@components/coaches/rowWithData";
import GridWrapper from "@components/elements/GridWrapper";
import MainLayout from "@components/layouts/main";
import BuilderIoSlider from "@components/sliders/builderIo/slider";
import VideoRowWithData from "@components/video/rowWithData";
import { Container } from "@material-ui/core";
import { withPageAuth } from "@utilities/next/withPageAuth";
import React from "react";

interface Props {
  sliderContents: BuilderContent[];
  auth: boolean;
}

export default function Home(props: Props) {
  const { auth, sliderContents } = props;

  return (
    <MainLayout title="Home" isAuth={auth}>
      <Container>
        <GridWrapper spacing={2}>
          {/* <MainSlider /> */}
          <BuilderIoSlider sliderContents={sliderContents} />
          <MainAbout />
          <VideoRowWithData title="Recently Added" />
          <CoachRowWithData title="With Coaches" />
        </GridWrapper>
        {/* <TagRowWithData title="With Equipments" /> */}
      </Container>
    </MainLayout>
  );
}

export const getServerSideProps = withPageAuth({
  permissions: [],
  redirectTo: "/browse",
  getServerSideProps: async (context) => {
    const builderApiKey = process.env.BUILDER_IO_API_KEY || "";

    builder.init(builderApiKey);
    const sliderContents = await builder.getAll("sliders", {
      url: context.resolvedUrl,
    });

    return { props: { sliderContents } };
  },
});
