import { queryFetcher } from "@utilities/helpers/queryFetcher";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";
import useSWR from "swr";

export function useListCoach<T>(defaultOptions?: QueryOptions) {
  const endpoint = "/api/v1/coaches";

  const [options, setOptions] = React.useState<QueryOptions>({
    populated: true,
    size: 10,
    ...defaultOptions,
  });

  const { data, revalidate } = useSWR([endpoint, options], queryFetcher);

  const result = data?.data as T[];

  return {
    data: result || [],
    count: data?.count || 0,
    error: data?.error || null,
    isLoading: !data?.data && !data?.error,
    reload: revalidate,
    setOptions,
  };
}
