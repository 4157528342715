import { UserProfile } from "@repositories/userProfile/types";

const NEXT_PUBLIC_BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN;
const IMAGE_URL = `${NEXT_PUBLIC_BASE_DOMAIN}/api/v1/images/source`;

export const getUserProfileAvatar = (avatar: UserProfile["avatar"]) => {
  const imageSrc = !!avatar
    ? `${IMAGE_URL}?provider=${avatar?.provider}&reference=${avatar?.reference}`
    : "";
  return imageSrc;
};
