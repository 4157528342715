import GridWrapper from "@components/elements/GridWrapper";
import Slider from "@components/elements/slider";
import VideoRowHeader from "@components/video/rowHeader";
import VideoRowItem from "@components/video/rowItem";
import { Box } from "@material-ui/core";
import { VideoPopulatedDocument } from "@repositories/video/types";
import React from "react";
import Slick, { Settings } from "react-slick";

interface Props {
  title?: string;
  slidesToShow?: number;
  headerActions?: React.ReactNode;
  videoList?: VideoPopulatedDocument[];
}

export default function VideoRow(props: Props): JSX.Element {
  const { title, headerActions, videoList = [] } = props;

  const count = videoList.length || 0;

  const sliderRef = React.useRef<Slick>();

  const sliderSettings: Settings = {
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 1280,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 960,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const listNodes = videoList.map((video) => (
    <Box p={0.5} height="100%">
      <VideoRowItem video={video} />
    </Box>
  ));

  return (
    <GridWrapper spacing={2}>
      <VideoRowHeader title={title} caption="Videos" actions={headerActions} />
      <Slider ref={sliderRef} settings={sliderSettings}>
        {React.Children.toArray([...listNodes])}
      </Slider>
    </GridWrapper>
  );
}
