import CoachCover from "@components/coaches/cover";
import CollectionList from "@components/collection/grid";
import CollectionListWithData from "@components/collection/gridWithData";
import GridWrapper from "@components/elements/GridWrapper";
import UserProfileSocialDisplay from "@components/userProfile/socials";
import UserResourceFollowButton from "@components/userResource/followButton";
import UserResourceRateButton from "@components/userResource/rateButton";
import VideoRowWithData from "@components/video/rowWithData";
import { useAuth } from "@hooks/auth/useAuth";
import { useRetrieveCoach } from "@hooks/coach/useRetrieve";
import { Box, CardActions, Divider, Typography } from "@material-ui/core";
import CheckBox from "@material-ui/icons/CheckBox";
import { UserProfilePopulatedDocument } from "@repositories/userProfile/types";
import { authUser } from "@services/auth/authUser";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";

interface Props {
  coachId: string;
}

export default function CoachDetail(props: Props): JSX.Element {
  const { coachId } = props;

  const { isAuth } = useAuth();

  const defaultOptions: QueryOptions = { populated: true };
  const [options] = React.useState<QueryOptions>(defaultOptions);
  const { data: coach } = useRetrieveCoach<UserProfilePopulatedDocument>(
    coachId,
    options,
  );

  const coachCover = coach?.cover;
  const coachName = coach?.name;
  const coachDescription = coach?.description;
  const coachSocials = coach?.meta?.socials || [];
  const coachTags = coach?.meta?.tags || [];

  return (
    <React.Fragment>
      <CoachCover
        cover={coachCover}
        title={coachName}
        subtitle={coachDescription}
      />
      <Box px={2}>
        <GridWrapper spacing={2}>
          <Box mt={4}>
            <Typography variant="h3">{coachName}</Typography>
            <Typography variant="h5">{coachDescription}</Typography>
            <Typography variant="caption">
              {coachTags.map((tag) => tag.name).join(", ")}
            </Typography>
          </Box>

          {isAuth && (
            <CardActions style={{}}>
              <UserResourceFollowButton
                resourceId={coachId}
                resourceType={"coach"}
              />
              <UserResourceRateButton
                resourceId={coachId}
                resourceType={"coach"}
              />
            </CardActions>
          )}
          {/* <Divider /> */}

          <UserProfileSocialDisplay socials={coachSocials} />

          <CollectionListWithData
            title="Collections By Coach"
            queryOptions={{ creator: coachId }}
          />

          <VideoRowWithData
            title="Videos By Coach"
            queryOptions={{ creator: coachId }}
          />
        </GridWrapper>
      </Box>
    </React.Fragment>
  );
}
