import CoachDialog, { CoachDialogRef } from "@components/coaches/dialog";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  alpha,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import { UserProfilePopulatedDocument } from "@repositories/userProfile/types";
import { getUserProfileAvatar } from "@utilities/web/getUserProfileAvatar";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    // backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },
  rootHover: {
    borderColor: alpha(theme.palette.primary.main, 0.5),
  },
  avatarBackground: {
    height: 0,
    paddingTop: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
  },
  avatarOverlay: {
    position: "absolute",
    top: 0,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-around",
    // background: `linear-gradient(0deg, rgba(50,62,72,1), 0%, rgba(123,134,140,0.4), 20%)`,
    // background: `linear-gradient(0deg, rgba(50,62,72,1) 0%, rgba(116,132,131,0) 30%, rgba(123,140,138,0) 35%)`,
    // "&:hover": {
    //   background: `linear-gradient(0deg, rgba(50,62,72,0.2), rgba(123,134,140,0.4))`,
    // },
    // background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 35%)`,
    // "&:hover": {
    //   background: `linear-gradient(0deg, rgba(255,255,255,0.2), rgba(255,255,255,0.4))`,
    // },
  },
  nameLabel: {
    position: "relative",
    bottom: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
    // borderTop: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
    // borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  },
}));

interface Props {
  coach: UserProfilePopulatedDocument;
}

export default function CoachRowItem(props: Props): JSX.Element {
  const { coach } = props;
  const coachAvatar = coach.avatar;
  const coachName = coach.name;

  const classes = useStyles();

  const avatarUrl = getUserProfileAvatar(coachAvatar);

  const coachDialogRef = React.useRef<CoachDialogRef>();

  const onOpen = () => {
    coachDialogRef.current.open();
  };

  return (
    <Card variant="outlined" className={classNames({ [classes.root]: true })}>
      <CardActionArea onClick={onOpen}>
        <Box
          // image={avatarUrl}
          className={classes.avatarBackground}
          style={{
            backgroundImage: `url(${avatarUrl})`,
            transition: "height 0.5s",
          }}
        />

        <CardContent>
          <Typography variant="subtitle2">{coachName}</Typography>
        </CardContent>
      </CardActionArea>

      <CoachDialog ref={coachDialogRef} coach={coach} />
    </Card>
  );
}
