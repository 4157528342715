import VideoRow from "@components/video/row";
import { useListVideo } from "@hooks/video/useList";
import { VideoPopulatedDocument } from "@repositories/video/types";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";

interface Props {
  title: string;
  queryOptions?: QueryOptions;
  headerActions?: React.ReactNode;
}

function VideoRowWithData(props: Props) {
  const { title, queryOptions, headerActions } = props;

  const { data: videoList } =
    useListVideo<VideoPopulatedDocument>(queryOptions);

  return (
    <VideoRow
      title={title}
      videoList={videoList}
      headerActions={headerActions}
    />
  );
}

export default VideoRowWithData;
