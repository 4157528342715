import CoachRowItem from "@components/coaches/rowItem";
import Slider from "@components/elements/slider";
import { Box } from "@material-ui/core";
import { UserProfilePopulatedDocument } from "@repositories/userProfile/types";
import React from "react";
import { Settings } from "react-slick";

interface Props {
  coachList: UserProfilePopulatedDocument[];
}

export default function CoachRow(props: Props): JSX.Element {
  const { coachList } = props;

  const sliderSettings: Settings = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const listNodes = coachList.map((coach) => (
    <Box p={0.5} height="100%">
      <CoachRowItem coach={coach} />
    </Box>
  ));

  return (
    <Slider settings={sliderSettings}>
      {React.Children.toArray(listNodes)}
    </Slider>
  );
}
