import { BuilderComponent, BuilderContent } from "@builder.io/react";
import React from "react";

import Slider from "react-slick";

interface Props {
  sliderContents: BuilderContent[];
}

function BuilderIoSlider(props: Props) {
  const { sliderContents } = props;

  return (
    <Slider>
      {sliderContents.map((slideContent, index) => (
        <BuilderComponent
          key={`slide-${index}`}
          model="sliders"
          content={slideContent}
        />
      ))}
    </Slider>
  );
}

export default BuilderIoSlider;
