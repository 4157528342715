import CoachRow from "@components/coaches/row";
import CoachRowHeader from "@components/coaches/rowHeader";
import GridWrapper from "@components/elements/GridWrapper";
import { useListCoach } from "@hooks/coaches/useList";
import { UserProfilePopulatedDocument } from "@repositories/userProfile/types";
import React from "react";

interface Props {
  title: string;
}

export default function CoachRowWithData(props: Props): JSX.Element {
  const { title } = props;

  const { data: coachList } = useListCoach<UserProfilePopulatedDocument>();

  return (
    <GridWrapper spacing={2}>
      <CoachRowHeader title={title} caption="Coaches" />
      <CoachRow coachList={coachList} />
    </GridWrapper>
  );
}
