import MainSectionHeader from "@components/layouts/main/sections/header";
import { UserProfileSocials } from "@constants/profiles";
import { CardActions, IconButton, Tooltip } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from "@material-ui/icons/Language";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import { UserProfileMetaSocial } from "@repositories/userProfile/types";
import React from "react";

const socialIcons: Record<UserProfileSocials, JSX.Element> = {
  Twitter: <TwitterIcon />,
  Facebook: <FacebookIcon />,
  Instagram: <InstagramIcon />,
  LinkedIn: <LinkedInIcon />,
  Website: <LanguageIcon />,
};

interface Props {
  socials: UserProfileMetaSocial[];
}

function UserProfileSocialDisplay(props: Props) {
  const { socials = [] } = props;

  const listNodes = socials.map((social) => (
    <Tooltip title={`${social.type}`} aria-label={`${social.type}`}>
      <IconButton href={`http://${social.link}`} target="_blank">
        {socialIcons[social.type]}
      </IconButton>
    </Tooltip>
  ));

  return socials.length ? (
    <React.Fragment>
      <MainSectionHeader caption="Contacts" title="Socials" />
      <CardActions>{React.Children.toArray(listNodes)}</CardActions>
    </React.Fragment>
  ) : (
    <></>
  );
}

export default UserProfileSocialDisplay;
