import CoachDetail from "@components/coaches/detail";
import DialogWrapper, { DialogWrapperRef } from "@components/wrappers/dialog";
import { DialogTitle, IconButton, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { UserProfilePopulatedDocument } from "@repositories/userProfile/types";
import React from "react";

interface Ref {
  open: () => void;
}

interface Props {
  coach: UserProfilePopulatedDocument;
}

function render(props: Props, ref: React.RefObject<Ref>): JSX.Element {
  const { coach } = props;
  const coachId = coach._id;

  const theme = useTheme();

  const dialogRef = React.useRef<DialogWrapperRef>();

  const onOpen = () => {
    dialogRef.current.open();
  };

  const onClose = () => {
    dialogRef.current.close();
  };

  const dialogTitle = (
    <DialogTitle
      style={{
        position: "absolute",
        background: "transparent",
        width: "100%",
        zIndex: theme.zIndex.modal + 1,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <IconButton style={{ borderRadius: 5 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );

  React.useImperativeHandle(ref, () => ({
    open: onOpen,
  }));

  return (
    <DialogWrapper
      ref={dialogRef}
      dialogTitle={dialogTitle}
      removeDialogTitle
      removeDialogContent
      dialogProps={{ maxWidth: "md", fullWidth: true }}
    >
      {coachId && <CoachDetail coachId={coachId} />}
    </DialogWrapper>
  );
}

const CoachDialog = React.forwardRef(render);

export default CoachDialog;
export interface CoachDialogRef extends Ref {}
